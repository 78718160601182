import React from "react";

function App() {
  return (
    <React.Fragment>
      <h1>自己紹介</h1>
      <img src={`${process.env.PUBLIC_URL}/yuyu_hf_400x400.png`} width="100" height="100"></img>
      <p>いっぬ（<a href="https://twitter.com/yuyu_hf">@yuyu_hf</a>）</p>
      <p>Software Engineer/修士(工学)<br></br>プロダクトエンジニアを目指しています。ソフトウェアアーキテクチャに興味があります。</p>
      <h1>仕事</h1>
      <p>合同会社DMM.comでDMMプラットフォームの認可の仕組みを開発しています。<br></br>
      認可チームではエンジニアを募集しています。認可チームに興味があれば以下の採用ページからいぬさん宛てにカジュアル面談を依頼してください。<br></br>
      <a href="https://dmm-corp.com/recruit/engineer/822/">https://dmm-corp.com/recruit/engineer/822/</a></p>
      <b>業務で使ったことがある技術</b>
      <ul>
        <li>Go, Python</li>
        <li>AWS, GCP</li>
      </ul>
      <h2>2024</h2>
      <ul>
        <li>
          （執筆予定）TBD @ DMMグループ Advent Calender 2024
        </li>
        <li>
          （登壇予定）クラウドネイティブなNewSQLで実現するミッションクリティカルなアプリケーションの運用 @ CloudNative Days Winter 2024
        </li>
        <li>
          他チームレビューのコツ @ 【KURAND社 協賛】仕事とお酒を愛でる「ソースコード」レビューまつり！（<a href="https://speakerdeck.com/yuyu_hf/other-teams-code-review-lt">リンク</a>）
        </li>
        <li>
          マイクロサービスを横断したGoのコードレビュー @ DMM.go #7（<a href="https://speakerdeck.com/yuyu_hf/dmm-go-7-microservices-go-code-review">リンク</a>）
        </li>
      </ul>
      <h2>2023</h2>
      <ul>
        <li>
          開発を通して感じたマイクロサービスを採用する大変さ @ DMMグループ Advent Calender 2023（<a href="https://inside.dmm.com/articles/dmm-platform-microservice-development-difficulties/">リンク</a>）
        </li>
        <li>
          Selecting TiDB Cloud for DMM microservices platform @ HTAP Summit 2023
        </li>
      </ul>
      <h2>2022</h2>
      <ul>
        <li>
          DMMプラットフォームを支える負荷試験基盤 @ CloudNative Days Tokyo 2022（<a href="https://speakerdeck.com/yuyu_hf/cndt-2022-dmm-load-testing-platform-for-dmm-platform">スライド</a>）
        </li>
        <li>
          DMMの取り組み最前線 ~フルマネージドNewSQLであるTiDB Cloudの可能性~ @ DB TECH SHOWCASE 2022（<a href="https://speakerdeck.com/yuyu_hf/db-tech-showcase-2022-dmm-inspect-full-managed-newsql-tidb-cloud">スライド</a>）
        </li>
        <li>
          マイクロサービスプラットフォーム向け負荷試験基盤の初期リリースを終えた話 @ DMM.go #4（<a href="https://speakerdeck.com/yuyu_hf/dmm-go-4-load-testing-first-release">スライド</a>）
        </li>
      </ul>
      <h1>個人</h1>
      <p>技術ブログ（<a href="https://zenn.dev/yuyu_hf">Zenn</a>）</p>
      <h2>2024</h2>
      <ul>
        <li>
          （執筆中）yuyu_hf のサーバーサイドアーキテクチャ
        </li>
      </ul>
    </React.Fragment>
  );
}

export default App;
